import { shapeTranslateLocale } from '@/config/shapeValueObject'

export const localeDataGeneral = {
    exploreFaq: {
        ...shapeTranslateLocale('Explore FAQs', 'Jelajahi FAQs')
    },
    learnMore: {
        ...shapeTranslateLocale('Learn More', 'Pelajari Selengkapnya')
    },
    viewAll: {
        ...shapeTranslateLocale('View All', 'Lihat Semua')
    },
    homeTabStory: {
        ...shapeTranslateLocale('Read the full story', 'Baca cerita lengkapnya')
    }
}

export const localeDataTitle = {
    footerTitle: {
        ...shapeTranslateLocale('EXPERIENCE THE CONNECTIVITY', 'RASAKAN KONEKTIVITASNYA')
    },
    footerInfo: {
        ...shapeTranslateLocale('Information', 'Informasi'),
    },
    footerContact: {
        ...shapeTranslateLocale('Contact Us', 'Kontak'),
    },
    footerMedia: {
        ...shapeTranslateLocale('Social Network', 'Jaringan Sosial'),
    },
    footerLanguage: {
        ...shapeTranslateLocale('Language', 'Bahasa'),
    },
}

export const localeDataPackage = {
    subscribeNow: {
        ...shapeTranslateLocale('Subscribe Now', 'Langganan Sekarang')
    },
    termAndCond: {
        ...shapeTranslateLocale('Terms and conditions apply', 'Syarat dan ketentuan berlaku')
    },
    period: {
        ...shapeTranslateLocale('Month', 'Bulan')
    },
    choosePlan: {
        ...shapeTranslateLocale('Choose your unlimited plan', 'Pilih paket unlimited anda')
    }
}

export const localeCoverageArea = {
    modalTitle: {
        ...shapeTranslateLocale('Choose Package', 'Pilih Paket')
    },
    modalSubTitle: {
        ...shapeTranslateLocale('Choose Your Unlimited Plan', 'Pilih Paket Unlimited Anda')
    },
    btnEditPackage: {
        ...shapeTranslateLocale('Edit', 'Ubah')
    }
}

export const localeTabCoverageArea = {
    tabForPersonal: {
        ...shapeTranslateLocale('For Personal', 'Untuk Pribadi')
    },
    tabForBusiness: {
        ...shapeTranslateLocale('For Business', 'Untuk Bisnis')
    },
}

export const localeFormLabelCoverageArea = {
    location: {
        ...shapeTranslateLocale('Location', 'Lokasi')
    },
    fullName: {
        ...shapeTranslateLocale('Full Name', 'Nama Lengkap')
    },
    email: {
        ...shapeTranslateLocale('Email', 'Email')
    },
    phoneNumber: {
        ...shapeTranslateLocale('Phone Number', 'Nomor Telepon')
    },
    address: {
        ...shapeTranslateLocale('Address', 'Alamat')
    },
    companyName: {
        ...shapeTranslateLocale('Company Name', 'Nama Perusahaan')
    },
    companyPhoneNumber: {
        ...shapeTranslateLocale('Company Phone Number', 'Nomor Telepon Perusahaan')
    },
    companyAddress: {
        ...shapeTranslateLocale('Company Address', 'Alamat Perusahaan')
    },
    agreeTermAndCond: {
        ...shapeTranslateLocale('I Agree to the', 'Saya menyetujui')
    },
    termAndCond: {
        ...shapeTranslateLocale('Terms and Conditions', 'Syarat dan Ketentuan')
    },
    requiredField: {
        ...shapeTranslateLocale('required field', 'kolom wajib diisi')
    },
    continue: {
        ...shapeTranslateLocale('Continue', 'Lanjutkan')
    },
    // btnEditPackage: shapeTranslateLocale('Edit', 'Ubah'),
}
