import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'
import { _convertInternalURLFile } from '@/helper/manageParameter'
import { useEffect, useState } from 'react'
import { useHookLayoutContext } from '@/component/hooks/MainContext'
import Link from 'next/link'
import { useRouter } from 'next/router'
import {
   localeLinkFooterInfoOne,
   localeLinkFooterInfoThree,
   localeLinkFooterInfoTwo,
   localeLinkRedirect,
} from '@/content/translate/locale-link'
import _ from 'lodash'
import { useHookCheckTranslateLink } from '@/helper/useTranslateLink'
import { localeDataTitle } from '@/content/translate/locale-data'
import { pathLogoWhiteNoSlogan } from '@/config/pathLogo'

const ContactWithLink = ({ description, link = '' }) => (
   <>
      {link ? (
         <Link href={link} target="_blank">
            {description}
         </Link>
      ) : (
         <p className="text-white">{description}</p>
      )}
   </>
)

const UIFlag = ({ src, alt }) => (
   <img
      className="ms-2"
      src={src}
      alt={alt}
      width="20px"
      height="auto"
      loading="lazy"
   />
)

const Footer = () => {
   const { __branch, __locale } = useHookLayoutContext()

   const router = useRouter()

   // const { __handleSwitchPageByCacheLocale } = useHookCheckTranslateLink()
   // const _handleSwitchLang = async (sign = '') => {
   //     __locale.handle(sign)
   //     // __handleSwitchPageByCacheLocale(sign)
   //
   //     const findURL = !_.isEmpty(localeLinkRedirect[sign])
   //         ? localeLinkRedirect[sign].find(
   //               (vm) => vm.pathname === router.pathname
   //           )
   //         : {}
   //
   //     const redirectTo = !_.isEmpty(findURL)
   //         ? findURL.redirect
   //         : router.pathname
   //
   //     // const dataURL =
   //
   //     // router.replace(router.pathname, redirectTo, {
   //     //     locale: sign,
   //     //     // shallow: true,
   //     //     // scroll: true,
   //     // })
   //
   //     __handleSwitchPageByCacheLocale(sign)
   // }

   return (
      <footer
         data-scroll-section
         // data-scroll-sticky
         // data-scroll-target=".accordion"
         className="d-flex vstack z-index-min-99 overflow-hidden">
         <div
            className="sitemap overflow-hidden w-100"
            data-scroll
            data-scroll-speed="-4"
            data-scroll-repeat>
            <img
               src="/images/icon/rectangle.png"
               alt="globalxtreme sitemap"
               className="bg-rectangle-sitemap"
               width="auto"
               height="auto"
               loading="lazy"
            />
            <div className="container-fluid px-md-5">
               <div className="row mb-4">
                  <div
                     className="col-12 col-lg-6 mb-4 mb-lg-0"
                     // data-scroll
                     // data-scroll-speed="-4"
                  >
                     <h2 className="text-uppercase text-primary-yellow mb-0">
                        {localeDataTitle.footerTitle[router.locale]}
                     </h2>
                  </div>
                  <div className="col-12 col-lg-6">
                     <div className="row information-link">
                        <div className="col-12">
                           <div className="row">
                              <div className="col-12">
                                 <p className="header-category">
                                    {localeDataTitle.footerInfo[router.locale]}
                                 </p>
                              </div>
                           </div>
                           <div className="row">
                              <div className="item-footer col-12 col-md-4 mb-3 mb-sm-0">
                                 <ul>
                                    {localeLinkFooterInfoOne.map(
                                       (data, index) => (
                                          <li key={index}>
                                             <Link
                                                href={data[router.locale].link}>
                                                {data[router.locale].name}
                                             </Link>
                                          </li>
                                       )
                                    )}
                                 </ul>
                              </div>
                              <div className="item-footer col-12 col-md-4">
                                 <ul>
                                    {localeLinkFooterInfoTwo.map(
                                       (data, index) => (
                                          <li key={index}>
                                             <Link
                                                href={data[router.locale].link}>
                                                {data[router.locale].name}
                                             </Link>
                                          </li>
                                       )
                                    )}
                                 </ul>
                              </div>
                              <div className="item-footer col-12 col-md-4">
                                 <ul>
                                    {localeLinkFooterInfoThree.map(
                                       (data, index) => (
                                          <li key={index}>
                                             <Link
                                                href={data[router.locale].link}>
                                                {data[router.locale].name}
                                             </Link>
                                          </li>
                                       )
                                    )}
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="row contact-link">
                        <div className="item-footer col-12 col-md-4 mb-4 mb-sm-0">
                           <p className="header-category">
                              {localeDataTitle.footerContact[router.locale]}
                           </p>
                           <ul className="">
                              <li>
                                 <ReactMarkdown
                                    rehypePlugins={[rehypeRaw]}
                                    className="text-white">
                                    {__branch?.data?.address || ''}
                                 </ReactMarkdown>
                              </li>

                              {__branch.data?.others?.map((vm, idx) => (
                                 <li key={idx}>
                                    <ContactWithLink {...vm} />
                                 </li>
                              )) || ''}
                           </ul>
                        </div>
                        <div className="item-footer col-12 col-md-4 mb-4">
                           <p className="header-category">
                              {localeDataTitle.footerMedia[router.locale]}
                           </p>
                           <ul>
                              <li>
                                 <a
                                    href="https://www.facebook.com/GlobalXtremeNet"
                                    target="_blank">
                                    Facebook
                                 </a>
                              </li>
                              <li>
                                 <a
                                    href="https://www.instagram.com/globalxtreme"
                                    target="_blank">
                                    Instagram
                                 </a>
                              </li>
                              <li>
                                 <a
                                    href="https://www.tiktok.com/@globalxtreme"
                                    target="_blank">
                                    TikTok
                                 </a>
                              </li>
                              <li>
                                 <a
                                    href="https://twitter.com/GlobalXtreme"
                                    target="_blank">
                                    X
                                 </a>
                              </li>
                              <li>
                                 <a
                                    href="https://www.linkedin.com/company/globalxtreme/mycompany"
                                    target="_blank">
                                    LinkedIn
                                 </a>
                              </li>
                           </ul>
                        </div>

                        {/*COMMENT SEMENTARA*/}
                        {/*<div className="item-footer col-12 col-md-4">*/}
                        {/*    <p className="header-category">*/}
                        {/*        {*/}
                        {/*            localeDataTitle.footerLanguage[*/}
                        {/*                router.locale*/}
                        {/*            ]*/}
                        {/*        }*/}
                        {/*    </p>*/}

                        {/*    <div*/}
                        {/*        className="btn-group"*/}
                        {/*        role="group"*/}
                        {/*        aria-label="Basic radio toggle button group">*/}
                        {/*        <input*/}
                        {/*            type="radio"*/}
                        {/*            className="btn-check"*/}
                        {/*            name="languageRb"*/}
                        {/*            id="btnradio1"*/}
                        {/*            value="en"*/}
                        {/*            onChange={() =>*/}
                        {/*                _handleSwitchLang('en')*/}
                        {/*            }*/}
                        {/*            autoComplete="off"*/}
                        {/*            // checked={*/}
                        {/*            //     __locale.data === 'en'*/}
                        {/*            //         ? true*/}
                        {/*            //         : false*/}
                        {/*            // }*/}
                        {/*            checked={*/}
                        {/*                router.locale === 'en'*/}
                        {/*                    ? true*/}
                        {/*                    : false*/}
                        {/*            }*/}
                        {/*        />*/}
                        {/*        <label*/}
                        {/*            className="btn btn-sm btn-outline-light-200"*/}
                        {/*            htmlFor="btnradio1">*/}
                        {/*            English{' '}*/}
                        {/*            <UIFlag*/}
                        {/*                src="/images/flags/en.png"*/}
                        {/*                alt="globalxtreme language english"*/}
                        {/*            />*/}
                        {/*        </label>*/}

                        {/*        <input*/}
                        {/*            type="radio"*/}
                        {/*            className="btn-check"*/}
                        {/*            name="languageRb"*/}
                        {/*            id="btnradio2"*/}
                        {/*            value="id"*/}
                        {/*            onChange={() =>*/}
                        {/*                _handleSwitchLang('id')*/}
                        {/*            }*/}
                        {/*            autoComplete="off"*/}
                        {/*            // checked={*/}
                        {/*            //     __locale.data === 'id'*/}
                        {/*            //         ? true*/}
                        {/*            //         : false*/}
                        {/*            // }*/}
                        {/*            checked={*/}
                        {/*                router.locale === 'id'*/}
                        {/*                    ? true*/}
                        {/*                    : false*/}
                        {/*            }*/}
                        {/*        />*/}
                        {/*        <label*/}
                        {/*            className="btn btn-sm btn-outline-light-200"*/}
                        {/*            htmlFor="btnradio2">*/}
                        {/*            Indonesia{' '}*/}
                        {/*            <UIFlag*/}
                        {/*                src="/images/flags/id.png"*/}
                        {/*                alt="globalxtreme bahasa indonesia"*/}
                        {/*            />*/}
                        {/*        </label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="footer-section w-100 mt-auto" data-scroll>
            <div className="container-fluid px-md-5">
               <div className="row align-items-center mb-3">
                  <div className="col-12 col-md-6 mb-4 mb-sm-0 d-none d-md-block">
                     <img
                        src={_convertInternalURLFile(pathLogoWhiteNoSlogan)}
                        // src={_convertInternalURLFile(
                        //     '/images/logo/logo-gx-white.svg'
                        // )}
                        alt="GlobalXtreme"
                        width="auto"
                        height="auto"
                        loading="lazy"
                     />
                  </div>
                  <div className="col-12 col-md-6">
                     <div className="row">
                        <div className="col-12 col-md">
                           {/*<p className="mb-0">© GlobalXtreme 2023</p>*/}
                           <p className="mb-3 mb-md-0">
                              <span className="opacity-50">
                                 PT. Internet Madju Abad Milenindo - ©1996 -{' '}
                                 {new Date().getFullYear()}
                              </span>{' '}
                              <span className="opacity-75">
                                 GlobalXtreme - Committed to better quality
                              </span>
                           </p>
                        </div>
                        {/* <div className="col-auto d-flex justify-content-end">
                           <p className="mb-0 opacity-50">
                              Design by{' '}
                              <Link
                                 href="https://fleava.com/"
                                 target="_blank"
                                 className="text-white"
                                 rel="noreferrer">
                                 Fleava
                              </Link>
                           </p>
                        </div> */}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </footer>
   )
}

export default Footer
